import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextReveal = makeShortcode("TextReveal");
const Paper = makeShortcode("Paper");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "transitioning-back-to-face-to-face-school"
    }}>{`Transitioning back to face-to-face school`}</h2>
    <p>{`Returning to school in-person after an extended period of online learning can be both exciting and nerve-wracking, even for teens who prefer face-to-face school. `}<b>{`Many teens would have adjusted to new routines and expectations during a period of online learning`}</b>{`, so the prospect of having to adjust to yet another ‘new normal’ can be daunting.`}</p>
    <TextReveal header="So how can I support my teen when they return to school? (click to reveal)" id="supportTextReveal" mdxType="TextReveal">
  <ul>
    <li>
      Have an open conversation with your teen about any concerns they have about returning to school in-person.
      Remember, it’s important for you to appear calm when talking with your teen (even if you feel anxious yourself!).
    </li>
    <li>Remind them about the positives, such as seeing friends and teachers.</li>
    <li>
      Talk with your teen about how you plan to adjust to changes in restrictions. Try to set a good example for your
      teen by being flexible in adapting to the new rules yourself.{' '}
    </li>
  </ul>
    </TextReveal>
    <p>{`Online learning also has its benefits for some teens. For example, it can provide temporary relief from social anxiety or social difficulties. If this is the case for your teen, their anxiety may `}<i>{`increase`}</i>{` when asked to return to school. They may find it hard to adjust to the change.`}</p>
    <p>{`If your teen is anxious about going back to school, try reaching out to the school staff to:`}</p>
    <ul>
      <li parentName="ul">{`Find ways to make your teen’s transition back to school safe and healthy.`}</li>
      <li parentName="ul">{`Identify ways to support your teen if they are worried about having fallen behind academically, or their future prospects.`}</li>
    </ul>
    <Paper elevation={0} style={{
      backgroundColor: '#bce2e3',
      borderRadius: '20px'
    }} mdxType="Paper">
  If your teen <b>refuses to return to school</b>, seek support from the school or from a mental health professional.
  Check out the <GoTo to="/resources" mdxType="GoTo">Useful Resources</GoTo> page for suggestions of where to seek professional
  support.
    </Paper>
    <p>{`To learn more about supporting your teen to manage their anxiety, see the module `}<GoTo to="/dashboard" mdxType="GoTo"><i>{`From surviving to thriving: Helping your teenager deal with anxiety`}</i></GoTo>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      